import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';

// Domain
import {
  GoalEarningsSegment,
} from '@/modules/flagship/investor-goal/goal-tracking/domain/dtos/goal-earnings-segment-dto';
import {
  GoalTrackingEntity,
} from '@/modules/flagship/investor-goal/goal-tracking/domain/entities/goal-tracking-entity';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class CustomProgressBarViewModel {
  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  readonly i18n_namespace = 'components.goals-dashboard.details-goals.custom-progress-bar';

  display_tooltip = false;

  segments: Array<GoalEarningsSegment> = []

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  getSource = (is_a_budget_goal: boolean) => (
    is_a_budget_goal ? this.translate('tooltip_budget') : this
      .translate('tooltip_2')
  );

  addSegment = (percent: number, amount: string, class_name: string, show_tooltip: boolean) => {
    this.segments.push({
      percent,
      amount,
      class_name,
      show_tooltip,
    });
  }

  setBudgetGoalSegments = (goal_tracking: GoalTrackingEntity) => {
    const earnings = goal_tracking.earnings || 0;
    const balance_without_earnings = (goal_tracking.current_goal_balance
      - earnings);

    const earnings_percent = Math.ceil((earnings * 100
    ) / goal_tracking.current_goal_balance);

    const balance_without_earnings_percent = 100 - earnings_percent;
    if (balance_without_earnings_percent >= 1) {
      this.addSegment(balance_without_earnings_percent, `${currencyFormat(balance_without_earnings)} MXN`, 'accent first', false);
    }

    if (earnings_percent >= 1) {
      this.addSegment(earnings_percent, `${currencyFormat(earnings)} MXN`, 'sky-blue last', true);
    } else if (this.segments.length > 0) {
      this.segments[0].class_name += ' last';
    }
  }

  setNotBudgetGoalSegments = (goal_tracking: GoalTrackingEntity) => {
    const earnings = goal_tracking.earnings || 0;
    const balance_without_earnings = (goal_tracking.current_goal_balance
      - earnings);
    const pending_balance = (goal_tracking.goal_target_balance
      - goal_tracking.current_goal_balance);

    const balance_without_earnings_percent = Math.ceil((balance_without_earnings * 100
    ) / goal_tracking.goal_target_balance);

    if (balance_without_earnings_percent >= 1) {
      this.addSegment(balance_without_earnings_percent, `${currencyFormat(balance_without_earnings)} MXN`, 'accent first', false);
    }

    const earnings_percent = Math.ceil((earnings * 100) / goal_tracking.goal_target_balance);
    if (earnings_percent >= 1) {
      this.addSegment(earnings_percent, `${currencyFormat(earnings)} MXN`, 'sky-blue', true);
    }

    const pending_balance_percent = 100 - balance_without_earnings_percent - earnings_percent;

    if (pending_balance_percent >= 1) {
      let class_name = 'inactive accent last';
      if (this.segments.length === 0) {
        class_name += ' first';
      }
      this.addSegment(pending_balance_percent, `${currencyFormat(pending_balance)} MXN`,
        class_name, false);
    } else if (this.segments.length > 2) {
      this.segments[1].class_name += ' last';
    }
  }

  setSegments = (goal_tracking: GoalTrackingEntity, is_a_budget_goal: boolean) => {
    this.segments.length = 0;
    if (goal_tracking) {
      if (is_a_budget_goal) {
        this.setBudgetGoalSegments(goal_tracking);
      } else {
        this.setNotBudgetGoalSegments(goal_tracking);
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  displayHelpTooltip = ({ mouseenter, mouseleave }, event: Event) => {
    event.preventDefault();
    event.stopPropagation();
    mouseenter(event);
    this.display_tooltip = !this.display_tooltip;
    if (this.display_tooltip) {
      mouseenter(event);
    }
    if (!this.display_tooltip) {
      mouseleave(event);
    }
  }
}
